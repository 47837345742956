
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { PaginationData } from '@/api/operation/customer';
import {
  getCouponGiftPack,
  initGiftPackList,
  GiftPackItem,
  reveicedNumber,
  getIncludeData,
  editCouponGiftPack,
  editPackageStatus,
  exportDataing,
} from '@/api/marketing/coupon';
const { VUE_APP_API: FCREST } = process.env;
const STATUS = [
  {
    value: 2,
    label: '全部',
  },
  {
    value: 1,
    label: '上架中',
  },
  {
    value: 0,
    label: '已下架',
  },
];
@Component
export default class GiftPack extends Vue {
  modal_value: any = {
    modal_type: 1,
    data: {},
    item: {},
    is_show_coupon_detail: false,
    is_show_status_modal: false,
  };
  is_loading: boolean = false;
  is_show_used_modal: boolean = false;
  is_show_include_modal: boolean = false;
  include_modal_title: string = '';
  received_modal_title: string = '';
  received_number_list: any = {};
  include_modal_data: any = {};
  form_value: any = {
    name: '',
    status: 2,
  };
  coupon_id_str = '';
  export_data_id: number = 0;
  get giftPackStatus() {
    return STATUS;
  }
  gift_pack: PaginationData<GiftPackItem> = initGiftPackList();
  // 获取优惠券礼包列表
  @changeLoading(['is_loading'])
  async getCouponGiftPack() {
    const res = await getCouponGiftPack();
    // console.error(res);

    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.gift_pack = initGiftPackList(res.data);
  }
  // 导出数据
  exportData() {
    console.log(this.received_number_list)
    if(this.received_number_list.length<=0){
      this.$message.error('暂无数据，无法导出')
    }else{
      exportDataing({package_id:this.export_data_id})
      // window.location.href = `${FCREST}/boss/marketing/package/user/export?package_id=${this.export_data_id}`;
    }
    //console.log(`/boss/marketing/package/user/export?package_id=${this.export_data_id}`);
   
  }

  // 清空输入框
  clearForm() {
    this.form_value = {
      name: '',
      status: 2,
    };
  }

  paginationOption(data?: any) {
    return {
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      total: data.total || 0,
      pageSize: data.limit || 1,
      current: +data.current || 1,
    };
  }

  // 显示包含模态窗
  @changeLoading(['is_loading'])
  async viewIncludeModal(item: any) {
    this.is_show_include_modal = true;
    this.include_modal_title = item.name;
    this.coupon_id_str = item.coupon_id_str;
    const res = await getIncludeData(item.coupon_id_str);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.include_modal_data = res.data;
  }

  // 显示已领取模态窗
  @changeLoading(['is_loading'])
  async viewUsedNumber(item: any) {
    this.is_show_used_modal = true;
    this.received_modal_title = item.name;
    const res = await reveicedNumber(item.id);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.received_number_list = res.data;
    this.export_data_id = item.id;
  }

  // 更换上下架状态
  @changeLoading(['is_loading'])
  async changeStatus() {
    /* const res = await editPackageStatus(item.id, {
      status: item.status === 1 ? 0 : 1,
    }); */
    const res = await editPackageStatus(
      this.modal_value.item.id,
      {status:this.modal_value.item.status === 1 ? 0 : 1}
    );
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.$message.success('编辑成功！');
    this.modal_value.is_show_status = false;
    await this.getCouponGiftPack();
    this.modal_value.is_show_status_modal = false;
  }
  showStatusModal(item: any) {
    this.modal_value.is_show_status_modal = true;
    this.modal_value.item = item;
  }

  // 点击切换按钮
  @changeLoading(['is_loading'])
  async handleChange(data: any) {
    await this.searchBtn(data.current);
  }
  // 包含优惠券翻页
  async includeModalChange(data: any) {
    const res = await getIncludeData(this.coupon_id_str, data.current);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.include_modal_data = res.data;
  }
  // 跳转编辑页面
  editCouponGiftPack(item: any) {
    this.$router.push(`/marketing/add-gift-pack?id=${item.id}`);
  }

  // 跳转券码页面
  goGiftPackCode(item: any) {
    this.$router.push(`/marketing/gift-pack-code?id=${item.id}&package_name=${item.name}`);
  }

  // 点击搜索按钮
  @changeLoading(['is_loading'])
  async searchBtn(current: number = 1) {
    console.log(this.form_value);
    const querList={
      name: this.form_value.name,
      status: this.form_value.status.toString(),
      page: 20,
      current,
    }
    if(this.form_value.status==2){
      delete querList.status
    }
    const res = await getCouponGiftPack(querList);
    
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.gift_pack = initGiftPackList(res.data);
  }

  @changeLoading(['is_loading'])
  async created() {
    await this.getCouponGiftPack();
  }
}
