import { render, staticRenderFns } from "./gift-pack.vue?vue&type=template&id=622221cc&scoped=true"
import script from "./gift-pack.vue?vue&type=script&lang=ts"
export * from "./gift-pack.vue?vue&type=script&lang=ts"
import style0 from "./gift-pack.vue?vue&type=style&index=0&id=622221cc&prod&lang=stylus&module=true"
import style1 from "./gift-pack.vue?vue&type=style&index=1&id=622221cc&prod&lang=stylus&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "622221cc",
  null
  
)

export default component.exports